import { loaderElement } from '../elements';

export class Loader {
  loaderElement: HTMLElement | null;

  constructor() {
    this.appendElements();

    this.loaderElement = document.getElementById('ebizio-loader');
  }

  appendElements() {
    const parentElement = document.getElementById('ebizio-loader-parent');
    if (!parentElement) {
      const myDiv = document.createElement('div');
      myDiv.id = 'ebizio-loader-parent';
      myDiv.innerHTML = loaderElement;
      document.body.appendChild(myDiv);
    }
  }

  show() {
    if (this.loaderElement) {
      this.loaderElement.style.display = 'block';
    }
  }

  hide() {
    if (this.loaderElement) {
      this.loaderElement.style.display = 'none';
    }
  }
}
