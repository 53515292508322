export const loaderElement = `
<style>
  #ebizio-loader {
      display: none;
      background: rgba(51,51,51,.9);
      bottom: 0;
      height: 100%;
      left: 0;
      opacity: 1;
      position: fixed;
      right: 0;
      top: 0;
      transition: all 250ms ease-out;
      width: 100%;
      z-index: 5000;
  }

  #ebizio-loader::before {
      height: 2.85714rem;
      width: 2.85714rem;
      border-radius: 2.85714rem;
      border: solid 2px;
      border-color: #fff #fff #999 #999;
      content: "";
      display: block;
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(0);
      transform-style: preserve-3d;
      animation: spin .5s infinite cubic-bezier(.69,.31,.56,.83);
  }
</style>

<div id="ebizio-loader"></div>
`;
